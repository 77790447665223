import React, { useState, useEffect } from "react";
import axios from "axios";
import EcoBin from "./EcoBin";
import "./App.scss";

import "./fonts.css";

function App() {
  const [errorMessages, setErrorMessages] = useState({});
  const [tokenActive, setTokenActive] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    const token = localStorage.getItem("token");
    if (token) {
      setTokenActive(true);
    } else {
      setTokenActive(false);
    }
  }, []);

  window.setInterval(function () {
    let date = new Date();
    console.log(date.getHours);
    if (date.getHours() === 4 && date.getMinutes() === 0) {
      window.location.reload();
    }
  }, 60000);

  const errors = {
    ecobin: "Field must be a number",
    serial: "Field must be a number",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let { ecobin, serial } = document.forms[0];

    // Compare user info
    if (ecobin.value && serial.value) {
      let isEcobinNum = /^\d+$/.test(ecobin.value);
      let isSerialNum = /^\d+$/.test(serial.value);
      if (!isEcobinNum) {
        // Invalid Ecobin Number
        setErrorMessages({ name: "ecobin", message: errors.ecobin });
      } else if (!isSerialNum) {
        // Invalid Serial Number
        setErrorMessages({ name: "serial", message: errors.serial });
      } else if (isEcobinNum && isSerialNum) {
        setIsSubmitted(true);
      }
    }

    if (isSubmitted) {
      const loginFormData = {
        ecobinId: parseInt(ecobin.value),
        serialNumber: parseInt(serial.value),
      };

      try {
        const response = await axios({
          method: "post",
          url: "https://api.venuenow.tech/auth/ecobin",
          data: loginFormData,
          headers: { "Content-Type": "application/json" },
        });
        localStorage.setItem("token", response.data.token);
        setTokenActive(true);
      } catch (error) {
        console.log(error);
        setLoginError(true);
      }
    }
  };

  // Error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // Login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>EcoBin Id</label>
          <input type="text" name="ecobin" required />
          {renderErrorMessage("ecobin")}
        </div>
        <div className="input-container">
          <label>Serial Number</label>
          <input type="text" name="serial" required />
          {renderErrorMessage("serial")}
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  return (
    <>
      <div className={`${tokenActive ? "'" : "login-form"}`}>
        <div className={`${tokenActive ? "title-hide" : "title"}`}>Sign In</div>
        {tokenActive ? <EcoBin /> : renderForm}
      </div>
      {loginError && (
        <div className={`${tokenActive ? "login-error-hide" : "login-error"}`}>
          Login Failed - Check credentials and try again
        </div>
      )}
    </>
  );
}

export default App;
