import React, { useEffect } from "react";
import "./ErrorScreen.scss";

import Error from "../Images/icon-Failure.png";
import NoviraLogo from "../Images/NoviraLogo.webp";

function AuthErrorScreen() {
  useEffect(() => {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  }, []);
  return (
    <div className="error__base">
      <div className="error__title">
        <h2 className="error__title__text">
          Error - Authentication error! Clearing cache and re-directing to login
          page.
        </h2>
      </div>
      <div className="error__image">
        <img className="error__image__logo" src={Error} alt="Error" />
      </div>
      <div className="error__footer">
        <img className="error__footer__logo" src={NoviraLogo} alt="Logo" />
      </div>
    </div>
  );
}

export default AuthErrorScreen;
