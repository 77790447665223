import React, { useState, useEffect } from "react";
import "./ScanContainerScreen.scss";

import Scan from "../Images/scan.gif";
import NoviraLogo from "../Images/NoviraLogo.webp";
import Co2Icon from "../Images/icon-carbon-footprint-white.png";
import HomeVideo from "../Images/Novira-Eco-Bin_eco-bin.mp4";

function ScanContainerScreen({ setPackageData, setActivePage, co2Count }) {
  const [showScanPackage, setShowScanPackage] = useState(true);
  const [switchTime, setSwitchTime] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowScanPackage((prev) => !prev);
    }, 26000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSwitchTime(!switchTime);
    }, 6200);
    return () => clearTimeout(timer);
  }, [switchTime]);

  const onBlur = (e) => {
    window.setTimeout(() => e.target.focus(), 0);
  };

  const videoScreen = () => {
    return (
      <div className="home__image">
        <video className="home__image__video" autoPlay loop muted>
          <source src={HomeVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };

  return (
    <div className="scanPackage__base">
      <div className="scanPackage__title">
        <h1 className="scanPackage__title__text">
          Scan to the right and place into bin
        </h1>
        <input
          className="packageScan__image__input"
          autoFocus
          type="text"
          onBlur={onBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setPackageData(e.target.value);
              setActivePage();
            }
          }}
        />
      </div>
      {showScanPackage ? (
        <>
          <div className="scanPackage__image">
            {switchTime ? (
              <div className="container">
                <h2 className="msg">CO2 emissions saved so far today (g)</h2>
                <div className="image">
                  <h5 className="image__co2"> {co2Count} g</h5>
                  <img src={Co2Icon} alt="Icon" className="image__img" />
                </div>
              </div>
            ) : (
              <img
                className="scanPackage__image__logo"
                src={Scan}
                alt="Scan "
              />
            )}
          </div>
          <div className="scanPackage__footer">
            <img
              className="scanPackage__footer__logo"
              src={NoviraLogo}
              alt="Logo"
            />
          </div>
        </>
      ) : (
        videoScreen()
      )}
    </div>
  );
}

export default ScanContainerScreen;
