import { React, useEffect, useState } from "react";
import "./ContainerScanned.scss";
import ScanSuccess from "../Images/ScanSuccess.gif";
import ScanSuccessLastFrame from "../Images/LastFrame.gif";
import NoviraLogo from "../Images/NoviraLogo.webp";

function ContainerScanned() {
  const [animation, setAnimation] = useState(ScanSuccess);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(ScanSuccessLastFrame);
    }, 800);
  }, []);

  return (
    <div className="scanned__base">
      <div className="scanned__title">
        <h1 className="scanned__title__text">Container sucessfully scanned!</h1>
      </div>
      <div className="scanned__image">
        <img className="scanned__image__logo" src={animation} alt="Tick" />
      </div>

      <div className="scanned__footer">
        <img
          className="scanned__footer__logo"
          src={NoviraLogo}
          alt="VenueNow"
        />
      </div>
    </div>
  );
}

export default ContainerScanned;
